import React from "react";
import { makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { CheckmarkCircledIcon, CheckmarkDottedCircledOutlineIcon } from "gx-npm-icons";
import styles from "../styles";
import { TypographyComponent } from "gx-npm-ui";
import PropTypes from "prop-types";

const darkPear = colorPalette.status.darkPear.hex;
const useStyles = makeStyles(() => styles);
const propTypes = {
  item: PropTypes.object,
};
const ChecklistRow = ({ item }) => {
  const classes = useStyles();
  return (
    <div className={classes.row}>
      {item?.complete ? <CheckmarkCircledIcon fillPath={darkPear} /> : <CheckmarkDottedCircledOutlineIcon />}

      <TypographyComponent rootClassName={classes.checklistName} styling={"p3"} color={"iron"} boldness={"medium"}>
        {item?.name}
      </TypographyComponent>
      <TypographyComponent rootClassName={classes.checklistStatus} styling={"p3"} color={"iron"} boldness={"regular"}>
        {item?.description}
      </TypographyComponent>
    </div>
  );
};
ChecklistRow.propTypes = propTypes;
export default ChecklistRow;
