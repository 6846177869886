import { colorPalette, weightPalette } from "gx-npm-common-styles";

const styles = {
  container: {
    minHeight: "129px",
    borderBottom: `1px solid ${colorPalette.neutrals.silver.hex}`,
    backgroundColor: colorPalette.basic.white.hex,
    "& .template-use-btn": {
      borderRadius: "0",
    },
    padding: "56px 0 24px 0",
  },
  boxShadow: {
    boxShadow: `0 8px 6px -4px ${colorPalette.neutrals.silver.hex}`,
  },
  templateHubNavLink: {
    marginBottom: "18px",
  },
  templateLabel: {
    width: "220px",
    marginRight: "22px",
    marginLeft: "auto",
  },
  buttonLabel: {
    fontWeight: weightPalette.bold.amount,
    fontVariationSettings: weightPalette.bold.variation,
  },
  templateHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  templateHeader: {
    width: "60%",
  },
  evalButtonTooltip: {
    top: "4px !important",
  },
};
export default styles;
