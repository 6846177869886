import React, { Fragment, useContext, useEffect } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Loader, TypographyComponent } from "gx-npm-ui";
import { EvaluationStateContext } from "../../../context";
import { getTemplateData } from "../../../context/actions/templateActions";
import styles from "./styles";
import { TEMPLATE_SECTION_OVERVIEW } from "../../../lib/constants";

const propTypes = { templateId: PropType.number, selectedMenu: PropType.bool };
const useStyles = makeStyles(() => styles);
const TemplateOverview = ({ templateId, selectedMenu }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, dispatch] = useContext(EvaluationStateContext);
  const { isLoading, data } = state?.template?.overview || {};

  useEffect(() => {
    if (templateId) {
      getTemplateData(dispatch, { templateId, section: TEMPLATE_SECTION_OVERVIEW });
    }
  }, [dispatch, templateId]);

  return (
    <Fragment>
      <TypographyComponent
        rootClassName={classNames(selectedMenu && "activeHeader")}
        styling={"h4"}
        color={"yam"}
        boldness={"medium"}
      >
        {data?.name}
        {t(" Template Overview")}
      </TypographyComponent>

      {isLoading && (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      )}
      {!isLoading && (
        <Fragment>
          <div className={classes.content}>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
              {data?.description}
            </TypographyComponent>
          </div>
          <div className={classes.content}>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
              {t("The template provides information such as")}
            </TypographyComponent>
            <TypographyComponent element={"span"} styling={"p2"} color={"black"} boldness={"medium"}>{` ${t(
              "detailed requirements, vendors to consider"
            )}`}</TypographyComponent>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
              {t(", a")}
            </TypographyComponent>
            <TypographyComponent element={"span"} styling={"p2"} color={"black"} boldness={"medium"}>{` ${t(
              "comprehensive checklist"
            )}`}</TypographyComponent>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>{` ${t(
              "and a"
            )}`}</TypographyComponent>
            <TypographyComponent element={"span"} styling={"p2"} color={"black"} boldness={"medium"}>{` ${t(
              "scorecard"
            )}`}</TypographyComponent>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
              {` ${t(
                "so that you can have confidence in your final decision. Use this template to manage the evaluation of different"
              )} `}
            </TypographyComponent>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>
              {data?.name || ""}
            </TypographyComponent>
            <TypographyComponent element={"span"} styling={"p2"} color={"coal"} boldness={"regular"}>{` ${t(
              "platforms."
            )}`}</TypographyComponent>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

TemplateOverview.propTypes = propTypes;
export default TemplateOverview;
