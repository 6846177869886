import React, { Fragment } from "react";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { TypographyComponent } from "gx-npm-ui";
import TeamScore from "../../../assets/images/teamScore.svg";
import VendorQuestionnaire from "../../../assets/images/vendorQuestionnaire.svg";
import styles from "./styles";

const propTypes = { selectedMenu: PropType.bool };
const useStyle = makeStyles(() => styles);
const EvaluationActivities = ({ selectedMenu = false }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  return (
    <Fragment>
      <TypographyComponent
        rootClassName={selectedMenu && "activeHeader"}
        styling={"h4"}
        boldness={"medium"}
        color={"carbon"}
      >
        {t("Evaluation activities")}
      </TypographyComponent>

      <Fragment>
        <div className={classes.container}>
          <div className={classes.leftContainer}>
            <TypographyComponent
              rootClassName={classes.contentHeader}
              color={"carbon"}
              styling={"p2"}
              boldness={"medium"}
            >
              {t("Vendor questionnaires")}
            </TypographyComponent>
            <TypographyComponent rootClassName={classes.contentText} color={"iron"} styling={"p3"} boldness={"regular"}>
              {t(
                "Request information from vendors and view their responses to evaluate surveyed vendors inside the application."
              )}
            </TypographyComponent>
            <img src={VendorQuestionnaire} alt="vendor questionnaire svg" />
          </div>
          <div className={classes.rightContainer}>
            <TypographyComponent
              rootClassName={classes.contentHeader}
              color={"carbon"}
              styling={"p2"}
              boldness={"medium"}
            >
              {t("Score with your team")}
            </TypographyComponent>
            <TypographyComponent rootClassName={classes.contentText} color={"iron"} styling={"p3"} boldness={"regular"}>
              {t(
                "Manage your evaluation with a custom scorecard built from your requirements. Collaborate with your team to compare and score each vendor."
              )}
            </TypographyComponent>
            <img src={TeamScore} alt="teamScore svg" />
          </div>
        </div>
      </Fragment>
    </Fragment>
  );
};

EvaluationActivities.propTypes = propTypes;
export default EvaluationActivities;
