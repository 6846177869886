import { makeStyles } from "@material-ui/core";
import React from "react";
import classNames from "classnames";
import styles from "./styles";
import { TypographyComponent } from "gx-npm-ui";
import PropTypes from "prop-types";
const useStyles = makeStyles(() => styles);
const propTypes = {
  textStyle: PropTypes.string,
  maxLine: PropTypes.number,
  text: PropTypes.string,
  hideTitle: PropTypes.bool,
};
const TruncateText = ({ textStyle, maxLine, text, hideTitle }) => {
  const classes = useStyles();
  return (
    <span
      style={{ WebkitLineClamp: maxLine || 1 }}
      title={!hideTitle && text ? text : null}
      className={classNames(classes.truncateStyle, textStyle)}
    >
      <TypographyComponent element={"span"} styling={"p2"} boldness={"regular"} color={"coal"}>
        {text}
      </TypographyComponent>
    </span>
  );
};
TruncateText.propTypes = propTypes;
export default TruncateText;
