import React from "react";
import { makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { ArrowIcon } from "gx-npm-icons";
import TruncateText from "../../../../ui/truncateText";
import styles from "./styles";
import { REQUIREMENTS_PRIORITY_MAP } from "../../../../lib/constants";
import PropTypes from "prop-types";
import { TypographyComponent } from "gx-npm-ui";

const propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    priority: PropTypes.number,
  }),
};
const useStyles = makeStyles(() => styles);
const RequirementRow = ({ item }) => {
  const classes = useStyles();
  return (
    <div className={classes.rowContainer}>
      <div className={classes.requirementDetails}>
        <TypographyComponent styling={"p2"} color={"carbon"} boldness={"medium"}>
          {item?.name}
        </TypographyComponent>

        <TruncateText
          text={item?.description}
          textStyle={classes.requirementDescription}
          maxLine={2}
          hideTitle={true}
        />
      </div>
      <div className={classes.requirementsPriority}>
        <TypographyComponent styling={"p3"} color={"carbon"} boldness={"medium"}>
          {REQUIREMENTS_PRIORITY_MAP[item?.priority]}
        </TypographyComponent>
        <ArrowIcon fillPath={colorPalette.neutrals.iron.hex} />
      </div>
    </div>
  );
};
RequirementRow.propTypes = propTypes;
export default RequirementRow;
