import React, { Fragment, useContext, useEffect } from "react";
import classNames from "classnames";
import PropType from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { colorPalette } from "gx-npm-common-styles";
import { ArrowIcon } from "gx-npm-icons";
import { Loader, TypographyComponent } from "gx-npm-ui";
import { EvaluationStateContext } from "../../../context";
import { getTemplateData } from "../../../context/actions/templateActions";
import ErrorBanner from "../../../ui/error-banner/error-banner.component";
import ChecklistRow from "./row";
import ProgressStatus from "./progressStatus/index";
import styles from "./styles";
import { TEMPLATE_SECTION_CHECKLIST } from "../../../lib/constants";

const propTypes = { templateId: PropType.number, selectedMenu: PropType.bool };
const useStyles = makeStyles(() => styles);
const Checklist = ({ templateId, selectedMenu }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [state, dispatch] = useContext(EvaluationStateContext);
  const { isLoading, data, error } = state.template.checklist;

  useEffect(() => {
    if (templateId) {
      getTemplateData(dispatch, { templateId, section: TEMPLATE_SECTION_CHECKLIST }, 3);
    }
  }, [dispatch, templateId]);

  return (
    <Fragment>
      <TypographyComponent
        rootClassName={selectedMenu && "activeHeader"}
        styling={"h4"}
        color={"carbon"}
        boldness={"medium"}
      >
        {t("Checklist")}
      </TypographyComponent>

      {!!isLoading && (
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      )}
      {!isLoading && !error && (
        <Fragment>
          <TypographyComponent rootClassName={classes.content} styling={"p2"} color={"coal"} boldness={"regular"}>
            {data?.description}
          </TypographyComponent>

          {data?.previewChecklist?.length && (
            <Fragment>
              <div className={classes.checklistTable}>
                <div className={classes.row}>
                  <div className={classNames(classes.arrowIcon, classes.iconContainer)}>
                    <ArrowIcon fillPath={colorPalette.neutrals.iron.hex} />
                  </div>

                  <TypographyComponent
                    rootClassName={classNames(classes.checklistName)}
                    styling={"p2"}
                    color={"carbon"}
                    boldness={"medium"}
                  >
                    {data.name}
                  </TypographyComponent>
                  <ProgressStatus status={data.status} />
                </div>
                {data.previewChecklist.map((item, index) => (
                  <ChecklistRow item={item} key={index} />
                ))}
              </div>
            </Fragment>
          )}
        </Fragment>
      )}
      {!isLoading && !!error && (
        <div className={classes.noData}>
          <ErrorBanner />
        </div>
      )}
    </Fragment>
  );
};

Checklist.propTypes = propTypes;
export default Checklist;
