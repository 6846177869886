import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { ExclamationCircledIcon } from "gx-npm-icons";
import styles from "./error-banner.style";
import { TypographyComponent } from "gx-npm-ui";

const useStyles = makeStyles(() => styles);
const ErrorBanner = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <ExclamationCircledIcon />
      <TypographyComponent rootClassName={classes.banner} styling={"p2"} color={"carbon"} element={"span"}>
        {t("Oops... we were unable to load this information.")}
      </TypographyComponent>
    </div>
  );
};

export default ErrorBanner;
