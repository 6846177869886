const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
    gap: "24px",
    paddingTop: "12px",
  },
  contentHeader: {
    width: "300px",
  },
  contentText: {
    paddingTop: "4px",
    paddingBottom: "32px",
  },
  leftContainer: {
    width: "363px",
  },
};

export default styles;
