import { colorPalette } from "gx-npm-common-styles";

const colorYam = colorPalette.brand.yam.hex;
const styles = {
  menuContainer: {
    display: "flex",
    flexDirection: "column",
  },
  menuItem: {
    backgroundColor: "white",
    border: "none",
    display: "block",
    height: "40px",
    lineHeight: "21px",
    padding: 0,
    textAlign: "left",
    width: "100%",
    "&.selected, &.selected:hover, &.selected:focus": {
      color: colorYam,
      "& $btnBorder": {
        visibility: "visible",
        backgroundColor: colorYam,
      },
    },
  },
  btnDisplay: {
    display: "flex",
  },
  btnChild: {
    display: "inline-flex",
    margin: "auto 0",
  },
  btnName: {
    paddingLeft: "16px",
    textAlign: "left",
    width: "164px",
    "&:hover": {
      color: colorYam,
      cursor: "pointer",
    },
  },
  btnBorder: {
    backgroundColor: colorPalette.neutrals.quartz.hex,
    height: "40px",
    width: "4px",
  },
};
export default styles;
