import { colorPalette } from "gx-npm-common-styles";

const styles = {
  container: {
    border: `1px solid ${colorPalette.neutrals.silver.hex}`,
    boxSizing: "border-box",
    width: "183px",
    height: "183px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingTop: "40px",
    boxShadow: "none",
    "&:hover": {
      cursor: "pointer",
      boxShadow: `0px 0px 20px rgba(20, 19, 18, 0.20)`,
    },
  },
  imageContainer: {
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    width: "50px",
    height: "50px",
  },
  productName: {
    textAlign: "center",
    width: "134px",
    "@media (min-width: 1440px)": {
      width: "162px",
    },
  },
};

export default styles;
